<template>
  <div>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="800px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.name"
                    v-bind:label="`Nombre Proyecto`"
                    required
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="selected.description"
                    clear-icon="mdi-close-circle"
                    label="Descripción"
                    row-height="15"
                    rows="5"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="selected?.static">
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-file-input
                    show-size
                    v-bind:label="`${$t('Upload')} ${$t('PDF File')}`"
                    @change="onChangePDFFile"
                    v-model="selected.static.file"
                    accept=".pdf"
                    required
                    :rules="[fileRules]"
                  >
                    <template v-if="selected.static.static" #label>
                      {{ selected.static.static.file }}
                    </template>
                  </v-file-input>
                  <v-btn
                    v-if="selected.static.static"
                    color="blue darken-1"
                    class="mb-2"
                    @click="downloadPDF(selected.static.static)"
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                    {{ `${$t("Download")} ${$t("PDF File")}` }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.responsibleId"
                    v-bind:label="`Responsable`"
                    item-text="name"
                    item-value="id"
                    :items="user_list"
                    clearable
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.brandId"
                    v-bind:label="`Marca`"
                    item-text="name"
                    item-value="id"
                    :items="brand_list"
                    clearable
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.templateType"
                    v-bind:label="`Tipo de Plantilla`"
                    :items="TEMPLATE_TYPES"
                    item-text="text"
                    item-value="value"
                    clearable
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    ref="startDate_menu"
                    v-model="select_startDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selected.startDate"
                        v-bind:label="$t('brands.start_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selected.startDate"
                      no-title
                      @input="select_startDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      required
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    ref="endDate_menu"
                    v-model="select_endDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selected.endDate"
                        v-bind:label="$t('brands.end_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selected.endDate"
                      no-title
                      scrollable
                      @input="select_endDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      required
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="onSaveClick(selected)"
              :loading="isSaving"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="530px">
      <v-card>
        <v-card-title class="text-h6">
          ¿Estás seguro de que quieres eliminar el proyecto?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t("cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>Vision Proyectos Global</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRef"
              id="projects_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="projects"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
              :scroll-width="1500"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totalprojects"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "GlobalProjects",
  data: function() {
    return {
      filterable_col_list: [
        "name",
        "brandId",
        "responsibleId",
        "status",
        "startDate",
        "endDate",
        "tasksOnTime",
        "tasksDelayed",
        "highPriorityTasksDelayed"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        status: {
          open: "open"
        },
        name: {},
        brandId: {},
        responsibleId: {},
        startDate: {},
        endDate: {},
        tasksOnTime: {},
        tasksDelayed: {},
        highPriorityTasksDelayed: {}
      },
      showEmpty: false,

      totalprojects: 0,
      projects: [],

      valid: true,
      requiredRules: [v => !!v || "Required"],
      fileRules: file => {
        if (file) {
          if (file.size <= 5e6) {
            return true;
          } else {
            return "File size should be less than 1 MB!";
          }
        }
        return true;
      },
      /********************* Dialog *********************/
      TEMPLATE_TYPES: [
        { text: "Inicio Proyecto", value: "PROJECT_START" },
        { text: "Aumento GPV's", value: "GPVINCREASE" },
        { text: "Nuevo Canal", value: "NEWCHANNEL" },
        { text: "Otros", value: "OTHERS" }
      ],
      user_list: null,
      brand_list: null,
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      showEdit: false,
      dialogDelete: false,
      select_startDate_menu: false,
      select_endDate_menu: false,
      isSaving: false
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isBrand"]),
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          key: "name",
          title: "Proyecto",
          field: "name",
          align: "left",
          fixed: "left",
          width: 130,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`projects/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "responsibleId",
          title: "Responsable",
          field: "responsibleId",
          align: "left",
          fixed: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>{row.user && row.user?.name + " " + row.user.surname}</span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["responsibleId"]}
                    apiUrl={`projects/getfilterlist`}
                    columnName={`responsibleId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["responsibleId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "responsibleId")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "brandId",
          title: "Marca",
          field: "brandId",
          align: "left",
          width: 180,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.brand?.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brandId"]}
                    apiUrl={`projects/getfilterlist`}
                    columnName={`brandId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brandId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brandId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: "Estado",
          field: "status",
          align: "left",
          width: 120,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.status === "OPEN" ? "ABIERTO" : "CERCA"}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["open"]}
                    label="ABIERTO"
                    value="open"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["close"]}
                    label="CERCA"
                    value="close"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "startDate",
          title: "Fecha Inicio",
          field: "startDate",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.startDate}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["from"]}
                    value={this.filterCriteria["startDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["to"]}
                    value={this.filterCriteria["startDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "startDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "endDate",
          title: "Fecha Final",
          field: "endDate",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.endDate}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["from"]}
                    value={this.filterCriteria["endDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["to"]}
                    value={this.filterCriteria["endDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "endDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "tasksCompleted",
          title: "Tareas completadas",
          field: "tasksCompleted",
          align: "left",
          width: 200
        },
        {
          key: "tasksPending",
          title: "Tareas pendientes",
          field: "tasksPending",
          align: "left",
          width: 200
        },
        {
          key: "tasksDelayed",
          title: "Tareas con Retraso",
          field: "tasksDelayed",
          align: "left",
          width: 200
        },
        {
          key: "highPriorityTasksDelayed",
          title: "Tareas Prio Alta con Retraso",
          field: "highPriorityTasksDelayed",
          align: "left",
          width: 200
        },
        {
          key: "docFile",
          title: "Adjuntos",
          field: "docFile",
          align: "center",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                {row.static && (
                  <v-btn
                    color="primary"
                    dark
                    fab
                    on-click={() => this.downloadPDF(row.static)}
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                  </v-btn>
                )}
              </div>
            );
          }
        },
        {
          key: "actions",
          title: "Editar",
          field: "actions",
          align: "center",
          fixed: "right",
          width: 120,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="info"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  medium
                  color="error"
                  class="mr-2"
                  on-click={() => this.onItemRemove(row.id)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          }
        },
        {
          key: "Ir",
          title: "Ir",
          field: "Ir",
          align: "center",
          fixed: "right",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={() => this.onItemGo(row)}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    async onItemGo(item) {
      this.$router.push({
        name: "individual_project",
        params: {
          id: item.id
        }
      });
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#projects_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        templateType: _.get(item, "templateType"),
        responsibleId: _.get(item, "responsibleId"),
        brandId: _.get(item, "brandId"),
        docFile: _.get(item, "docFile"),
        startDate: _.get(item, "startDate"),
        endDate: _.get(item, "endDate"),
        static: {
          file: null,
          static: _.get(item, "static")
        }
      };
      if (!model.id) model["responsibleId"] = this.user.id;
      return model;
    },

    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    async onChangePDFFile(file) {
      this.selected.static.file = file;
    },

    async downloadPDF(document) {
      this.show();
      let file_uri = ApiService.getFileUri(document ? document : null);
      if (file_uri !== null) {
        console.log(file_uri);
        try {
          const response = await ApiService.get(file_uri, {
            responseType: "arraybuffer"
          });
          download(response, document.file);
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },

    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        if (item.startDate && item.endDate) {
          if (item.startDate > item.endDate) {
            logError(this.$t("Error") + " - " + this.$t("brands.start_date"));
            return;
          }
        }
        let body = _.omit(item, ["static"]);
        try {
          this.isSaving = true;
          let file = item.static.file;
          if (file) {
            let { data } = await ApiService.uploadFile(file);
            body.docFile = data.id;
          }
          if (body.id) {
            await ApiService.put(`projects/${body.id}`, body);
          } else {
            await ApiService.post(`projects`, body);
          }
          logInfo("proyectos actualizada");
          this.showEdit = false;
          this.isSaving = false;
          await this.getDataFromApi();
        } catch (error) {
          this.isSaving = false;
          this.showEdit = false;
          logError(error);
        }
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    onItemRemove(item_id) {
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`projects/${this.deleteItemId}`).then(() => {
          logInfo("Proyectos eliminada");
          this.getDataFromApi();
        });
      }
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      query_params.push("today=" + today);

      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#projects_table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "projects";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.projects = resp.data;
      this.totalprojects = resp.total;
      this.brand_list = resp.brand_list;
      this.user_list = resp.user_list;
      if (this.projects.length > 0) {
        this.showEmpty = false;
      } else {
        this.showEmpty = true;
      }
      this.close();
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
